import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faUser } from '@fortawesome/free-regular-svg-icons';
import './BottomNavBar.css'; // Create a separate CSS file for the nav bar styling

const BottomNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    // Set the current tab based on the current URL path
    if (location.pathname === '/home') {
      setCurrentTab('home');
    } else if (location.pathname === '/events') {
      setCurrentTab('events');
    } else if (location.pathname === '/profile') {
      setCurrentTab('profile');
    }
  }, [location.pathname]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="bottom-nav">
      <button
        className={`nav-button ${currentTab === 'home' ? 'selected' : ''}`}
        onClick={() => handleNavigate('/home')}
      >
        <FontAwesomeIcon icon={faHome} size="lg" />
        <span>Home</span>
      </button>
      <button
        className={`nav-button ${currentTab === 'events' ? 'selected' : ''}`}
        onClick={() => handleNavigate('/events')}
      >
        <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
        <span>Events</span>
      </button>
      <button
        className={`nav-button ${currentTab === 'profile' ? 'selected' : ''}`}
        onClick={() => handleNavigate('/profile')}
      >
        <FontAwesomeIcon icon={faUser} size="lg" />
        <span>Profile</span>
      </button>
    </div>
  );
};

export default BottomNavBar;
