import React, { useState } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { ORGANIZER_TICKETS_QUERY, ORGANIZER_TICKET_QUERY } from '../graphql/queries';
import { ADD_SUPPORT_TICKET_MUTATION, ADD_SUPPORT_TICKET_COMMENT_MUTATION } from '../graphql/mutations';
import BottomNavBar from './BottomNavBar';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Card,
  CardHeader,
  Fab,
  Modal,
  Drawer,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '12px',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  backgroundColor: '#020420',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#1c1c4d',
  },
};

const SupportPage = () => {
  const [newTicket, setNewTicket] = useState({ title: '', comment: '' });
  const [replyComment, setReplyComment] = useState({});
  // const [loadingNewMessage, setLoadingNewMessage] = useState(false);
  const [loadingReply, setLoadingReply] = useState(false);
  const [activeTicket, setActiveTicket] = useState(null); // For opening a conversation thread
  const [isModalOpen, setIsModalOpen] = useState(false); // For creating a new ticket
  // const [isConversationModalOpen, setIsConversationModalOpen] = useState(false); // For viewing a conversation
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loadingConversation, setLoadingConversation] = useState(false);



  const { data, loading, error, refetch } = useQuery(ORGANIZER_TICKETS_QUERY);
  const [conversationData, setConversationData] = useState([]);


  const [addSupportTicket] = useMutation(ADD_SUPPORT_TICKET_MUTATION, {
    onCompleted: () => {
      setNewTicket({ title: '', comment: '' });
      // setLoadingNewMessage(false);
      setIsModalOpen(false); // Close modal
      refetch();
    },
    onError: (error) => {
      console.error('Error adding support ticket:', error);
      alert('Failed to create a new ticket.');
      // setLoadingNewMessage(false);
    },
  });

  const [addSupportTicketComment] = useMutation(ADD_SUPPORT_TICKET_COMMENT_MUTATION, {
    onCompleted: () => {
      setReplyComment({});
      setLoadingReply(false);
      refetch();
    },
    onError: (error) => {
      console.error('Error adding comment:', error);
      alert('Failed to add a comment.');
      setLoadingReply(false);
    },
  });

  const handleReplySubmit = async () => {
    const comment = replyComment[activeTicket]?.trim();
    if (!comment) {
      alert('Please enter a comment.');
      return;
    }
    setLoadingReply(true);
    try {
      // Submit the comment
      await addSupportTicketComment({ variables: { input: { supportTicketId: activeTicket, comment } } });
  
      // Refetch the updated conversation after submitting the comment
      setLoadingConversation(true);
      const { data } = await client.query({
        query: ORGANIZER_TICKET_QUERY,
        variables: { input: { ticketId: activeTicket } },
        fetchPolicy: 'network-only', // Ensures we are not using cached data
      });
  
      if (data && data.organizerTicket) {
        setConversationData(data.organizerTicket);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
      alert('Failed to add a comment.');
    } finally {
      setLoadingReply(false);
      setLoadingConversation(false);
    }
  };
  
  

  const handleNewTicketSubmit = () => {
    const { title, comment } = newTicket;
    if (!title.trim() || !comment.trim()) {
      alert('Please provide both a title and a comment.');
      return;
    }
    // setLoadingNewMessage(true);
    addSupportTicket({ variables: { input: { title, comment } } });
  };



  const client = useApolloClient(); // Add this at the start of your component

  const handleTicketClick = async (ticketId) => {
    setActiveTicket(ticketId);
  setLoadingConversation(true);
  setIsDrawerOpen(true);
  
    try {
      const { data } = await client.query({
        query: ORGANIZER_TICKET_QUERY,
        variables: { input: { ticketId } },
      });
  
      if (data && data.organizerTicket) {
        setConversationData(data.organizerTicket);
      }
    } catch (error) {
      console.error('Error fetching ticket conversation:', error);
      alert('Failed to load the conversation. Please try again.');
    } finally {
      setLoadingConversation(false);
    }
  };
  
  

  if (loading) return <CircularProgress sx={{ display: 'block', margin: 'auto', mt: 3 }} />;
  if (error) return <p>Error loading support tickets.</p>;

  const tickets = data?.organizerTickets || [];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ flex: 1, overflow: 'auto', padding: 2, backgroundColor: '#f7f7f7' }}>
        <Typography variant="h4" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' }}>
          Support Center
        </Typography>

        {/* Ticket List */}
        <Box>
          {tickets?.map((ticket) => (
            <Card
              key={ticket.id}
              sx={{
                mb: 2,
                cursor: 'pointer',
                backgroundColor: '#fff',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#f0f4ff',
                },
              }}
              onClick={() => handleTicketClick(ticket.id)}
            >
              <CardHeader
                title={ticket.title}
                subheader={`Last updated: ${new Date(
                  ticket.comments?.slice(-1)[0]?.createdAt || new Date()
                ).toLocaleString()}`}
              />
            </Card>
          ))}
        </Box>

        {/* Floating Action Button for New Tickets */}
        <Fab
          onClick={() => setIsModalOpen(true)}
          sx={{
            position: 'fixed',
            bottom: '120px', // Higher placement (adjust as needed)
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000, // Keep it above all other elements
            backgroundColor: '#020420',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#1c1c4d',
            },
          }}
        >
          <AddIcon />
        </Fab>
      </Box>

      {/* Modal for Creating New Ticket */}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} aria-labelledby="modal-title">
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" gutterBottom>
            Create New Ticket
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleNewTicketSubmit();
            }}
          >
            <TextField
              label="Title"
              name="title"
              fullWidth
              value={newTicket.title}
              onChange={(e) => setNewTicket((prev) => ({ ...prev, title: e.target.value }))}
              required
              margin="normal"
            />
            <TextField
              label="Comment"
              name="comment"
              fullWidth
              multiline
              rows={4}
              value={newTicket.comment}
              onChange={(e) => setNewTicket((prev) => ({ ...prev, comment: e.target.value }))}
              required
              margin="normal"
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button type="submit" variant="contained" sx={buttonStyle}>
                Create Ticket
              </Button>
              <Button onClick={() => setIsModalOpen(false)} variant="outlined" color="secondary">
                Close
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      {/* <Modal
        open={isConversationModalOpen}
        onClose={() => setIsConversationModalOpen(false)}
        aria-labelledby="conversation-modal-title"
      >
        <Box sx={{ ...modalStyle, width: 600 }}>
          <Typography id="conversation-modal-title" variant="h6" gutterBottom>
            Conversation
          </Typography>
          <Box sx={{ maxHeight: '400px', overflowY: 'auto', mb: 2 }}>
            {tickets
              .find((ticket) => ticket.id === activeTicket)
              ?.comments?.map((comment, index) => (
                <Box
                  key={index}
                  sx={{
                    mb: 2,
                    padding: 1,
                    borderRadius: '8px',
                    backgroundColor: comment.isResponse ? '#f0f4ff' : '#f9f9f9',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: comment.isResponse ? 'bold' : 'normal',
                      color: comment.isResponse ? '#003366' : '#666',
                    }}
                  >
                    {comment.isResponse ? 'Support:' : 'You:'}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {comment.text}
                  </Typography>
                  <Typography variant="caption" sx={{ color: '#999' }}>
                    {new Date(comment.createdAt).toLocaleString()}
                  </Typography>
                </Box>
              ))}
          </Box>
          <TextField
            label="Reply"
            placeholder="Type your message here..."
            fullWidth
            multiline
            rows={2}
            value={replyComment[activeTicket] || ''}
            onChange={(e) =>
              setReplyComment((prev) => ({ ...prev, [activeTicket]: e.target.value }))
            }
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            onClick={() => handleReplySubmit(activeTicket)}
            disabled={loadingReply}
            sx={{
              backgroundColor: '#020420',
              color: '#fff',
              '&:hover': { backgroundColor: '#1c1c4d' },
            }}
            endIcon={<SendIcon />}
          >
            {loadingReply ? <CircularProgress size={24} /> : 'Send'}
          </Button>
        </Box>
      </Modal> */}

<Drawer anchor="bottom" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
  <Box sx={{ padding: 3, height: '80vh', display: 'flex', flexDirection: 'column' }}>
    {/* Title Section */}
    <Typography variant="h6" gutterBottom>
      Conversation
    </Typography>

    {/* Scrollable Chat Section */}
    <Box sx={{ flex: 1, overflowY: 'auto', mb: 2, padding: 1 }}>
      {loadingConversation ? (
        <CircularProgress sx={{ display: 'block', margin: 'auto', mt: 3 }} />
      ) : (
        <Box sx={{ mb: 2 }}>
          {conversationData?.map((comment, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: comment.isResponse ? 'row' : 'row-reverse',
                mb: 2,
              }}
            >
              <Box
                sx={{
                  maxWidth: '75%',
                  padding: 1.5,
                  borderRadius: '8px',
                  backgroundColor: comment.isResponse ? '#f0f4ff' : '#e0f7fa',
                  textAlign: comment.isResponse ? 'left' : 'right',
                }}
              >
                <Typography variant="body2">{comment.text}</Typography>
                <Typography variant="caption" sx={{ color: '#999' }}>
                  {new Date(comment.createdAt).toLocaleString()}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>

    {/* Reply Box Section (Fixed) */}
    <Box sx={{ mt: 2 }}>
      <TextField
        label="Reply"
        placeholder="Type your message here..."
        fullWidth
        multiline
        rows={2}
        value={replyComment[activeTicket] || ''}
        onChange={(e) =>
          setReplyComment((prev) => ({ ...prev, [activeTicket]: e.target.value }))
        }
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        onClick={handleReplySubmit}
        disabled={loadingReply}
        sx={{
          backgroundColor: '#020420',
          color: '#fff',
          '&:hover': { backgroundColor: '#1c1c4d' },
        }}
        endIcon={<SendIcon />}
      >
        {loadingReply ? <CircularProgress size={24} /> : 'Send'}
      </Button>
    </Box>
  </Box>
</Drawer>


      {/* Bottom navigation bar */}
      <BottomNavBar />
    </Box>
  );
};

export default SupportPage;
