import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    // While loading, show a loading spinner or placeholder
    return <div>Loading...</div>; // Or render a spinner
  }

  return isAuthenticated ? element : <Navigate to="/sign-in" />;
};

export default ProtectedRoute;
