import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle, faUser} from '@fortawesome/free-solid-svg-icons';
import QrScanner from 'react-qr-scanner';
import './CheckInPage.css';
import { SCAN_QR_CODE, CREATE_EVENT_ENTRY_STREAM } from '../graphql/mutations';
import { useMutation } from '@apollo/client';
// import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
// import { API } from "aws-amplify";

// GraphQL query to fetch events
const GET_EVENTS_QUERY = gql`
  query GetEvents {
    getEvents {
      id
      name
      type
      venue
      startDate
      endDate
      description
    }
  }
`;

const CheckInPage = () => {
  const navigate = useNavigate();
  const [selectedEvent, setSelectedEvent] = useState('');
  const [checkedInCount, setCheckedInCount] = useState(0);
  const [scanMode, setScanMode] = useState(''); // 'liveCamera' or 'qrCode'
  const [qrResult, setQrResult] = useState(null);
  const [checkedInUsers, setCheckedInUsers] = useState([]);
  const videoRef = useRef(null);
  const [videoStream, setVideoStream] = useState(null);


  // Fetch events using useQuery
  const { data, loading, error } = useQuery(GET_EVENTS_QUERY);


  const [scanQRCode] = useMutation(SCAN_QR_CODE);
  const [createEventEntryStream] = useMutation(CREATE_EVENT_ENTRY_STREAM);


  useEffect(() => {
    if (scanMode === 'qrCode') {
      startCamera();
    }
  }, [scanMode]);
  
  

  useEffect(() => {
    return () => {
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [videoStream]);
  
  


  const handleEventChange = (e) => {
    setSelectedEvent(e.target.value);
  };

  const handleCheckIn = (mode) => {
    if (selectedEvent) {
      setScanMode(mode); // Set mode to 'liveCamera' or 'qrCode'
    } else {
      alert('Please select an event to proceed.');
    }
  };

  
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { exact: 'environment' } }, // Try back camera
      });
      setVideoStream(stream);
    } catch (err) {
      console.warn('Back camera not available, switching to front camera:', err);
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'user' }, // Fallback to front camera
        });
        setVideoStream(stream);
      } catch (err) {
        console.error('Error accessing front camera:', err);
        alert('Unable to access the camera. Please check permissions.');
      }
    }
  };
  


  const handleScan = async (data) => {
    if (data) {
      try {
        const qrData = data.text;
        const eventId = selectedEvent;

        const { data: mutationData } = await scanQRCode({
          variables: {
            input: {
              eventId,
              qrData,
            },
          },
        });

        const { firstName, preferredName } = mutationData.scanQRCode;

        alert(`User ${preferredName || firstName} has been checked in.`);

        setCheckedInCount(checkedInCount + 1);
        setQrResult(qrData);
        setCheckedInUsers([...checkedInUsers, { firstName, preferredName }]);
      } catch (err) {
        console.error('Error scanning QR code:', err);
        alert('Failed to check in the user.');
      }
    }
  };


  const handleLiveCameraCheckIn = async () => {
    if (!selectedEvent) {
      alert('Please select an event to proceed.');
      return;
    }
  
    try {
      // Prepare the payload
      const payload = {
        eventId: selectedEvent, // Dynamically set the selected event ID
      };
  
      // Call the CreateEventEntryStream mutation
      const { data } = await createEventEntryStream({
        variables: { input: payload },
      });

    // Safely log and store the stream ID
    if (data?.createEventEntryStream) {
      // console.log('Event entry stream created:', data.createEventEntryStream);
      // localStorage.setItem('streamId', data.createEventEntryStream);
      navigate("/live", { state: { streamId:  data?.createEventEntryStream, eventId: selectedEvent } });
    } else {
      console.error('Stream ID is undefined or missing in the response:', data);
    }


    } catch (err) {
      console.error('Error creating event entry stream:', err);
      alert('Failed to create event entry stream.');
    }
  };

  const handleError = (err) => {
    console.error('QR Code scan error:', err);
  };

  if (loading) return <p>Loading events...</p>;
  if (error) return <p>Error loading events: {error.message}</p>;

  return (
    <div className="check-in-page">
      <button className="back-button" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>

      {/* Event Selection and Check-In Options */}
      {scanMode === '' && (
        <div className="check-in-card">
          <h1>Event Check-In</h1>
          <div className="form-group">
            <label htmlFor="eventSelect">Select an Event:</label>
            <select
              id="eventSelect"
              value={selectedEvent}
              onChange={handleEventChange}
              className="event-select"
            >
              <option value="" disabled>
                -- Choose an Event --
              </option>
              {data.getEvents.map((event) => (
                <option key={event.id} value={event.id}>
                  {event.name}
                </option>
              ))}
            </select>
          </div>

          <button
              className="check-in-button"
              onClick={handleLiveCameraCheckIn}
            >
            Start Live Camera Check-In
          </button>

          <button
            className="check-in-button"
            onClick={() => handleCheckIn('qrCode')}
          >
            Start QR Code Check-In
          </button>
        </div>
      )}

      {/* Live Camera Check-In */}
      {scanMode === 'liveCamera' && (
        <div className="live-camera-check-in">
          <h2>Live Camera Check-In</h2>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            className="camera-feed"
            onCanPlay={startCamera}
          />
          <button
            className="check-in-button"
            onClick={() => {
              setCheckedInCount(checkedInCount + 1);
              alert('Live Camera Check-In Successful!');
            }}
          >
            Check-In
          </button>
          <button
            className="check-in-button"
            onClick={() => setScanMode('')}
          >
            Back
          </button>
        </div>
      )}

      {/* QR Code Scanner */}
      {scanMode === 'qrCode' && (
        <div className="qr-code-scanner">
          <h2>Scan QR Code</h2>
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
            videoStream={videoStream}
          />

<button
            className="check-in-button"
            onClick={() => setScanMode('')}
          >
            Back
          </button>
          {qrResult && (
  <div className="qr-result-card">
    <h2>
      <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '8px' }} />
      Check-In Successful!
    </h2>
    <p><strong>Scanned QR Code Data:</strong> {qrResult}</p>
    <p><strong>Total Checked-In Users:</strong> {checkedInCount}</p>

    {checkedInUsers.length > 0 && (
      <div className="checked-in-users-list">
        <h3>Checked-In Users:</h3>
        <ul>
          {checkedInUsers.map((user, index) => (
            <li key={index}>
              <FontAwesomeIcon icon={faUser} style={{ marginRight: '8px' }} />
              {user.preferredName || user.firstName}
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
)}
        </div>
      )}
    </div>
  );
};
export default CheckInPage;
