import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import IosShareIcon from '@mui/icons-material/IosShare';
import HomeScreenIcon from '@mui/icons-material/AddHome';
// import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';

import HomePage from './components/HomePage';
import SignInPage from './components/SignInPage';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import SignUpPage from './components/SignUpPage';
import ProfilePage from './components/ProfilePage';
import EventsPage from './components/EventsPage';
import EventDetailsPage from './components/EventDetailsPage';
import LandingPage from './components/LandingPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import CheckInPage from './components/CheckInPage';
import SupportPage from './components/SupportPage';
import './App.css';
import LivePage from './components/LivePage';

import { styled, ThemeProvider } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';


import {Loader} from '@aws-amplify/ui-react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { View,Flex,Text,Alert,Image } from '@aws-amplify/ui-react';
import awsexports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);



const CustomConnector = styled(StepConnector, {
  shouldForwardProp: (prop) => prop !== 'isIOS',
})(({ theme, isIOS }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    left: isIOS ? '-50%' : '0%',
    right: isIOS ? '-50%' : '0%',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[400],
  },
}));


const CustomStepIcon = ({ icon }) => (
  <Box
    sx={{
      backgroundColor: '#fff', // White background
      borderRadius: '50%',     // Makes the box round
      width: 50,
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 20,
    }}
  >
    {React.cloneElement(icon, { sx: { fontSize: 30 } })}
  </Box>
);


const App = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);
  const [skipInstall, setSkipInstall] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const iconStyles = { fontSize: 40, color: 'inherit' };
  const labelStyles = { mt: 1, textAlign: 'center' };
  



  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };
    
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  
    window.addEventListener('appinstalled', () => {
      console.log('App installed');
      setIsInstalled(true);
    });
  
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstalled(true);
    }
  
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);
  

  const handleInstall = () => {
    if (deferredPrompt) {
      console.log('Prompting installation...');
      deferredPrompt.prompt(); // Show the install prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        console.log(
          choiceResult.outcome === 'accepted'
            ? 'User accepted the installation.'
            : 'User dismissed the installation.'
        );
        setDeferredPrompt(null); // Clear the deferredPrompt after usage
      });
    } else {
      console.log(
        'deferredPrompt is null. Installation is not supported or the event was not triggered.'
      );
    }
  };

  if (!isInstalled && !skipInstall) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          background: 'linear-gradient(135deg, #020420, #1c1c4d)',
          color: '#fff',
          p: 3,
        }}
      >
        <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold' }}>
          Install Our App for the Best Experience!
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Get quick access, better performance, and enhanced features.
        </Typography>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#ff9800',
              color: '#fff',
              '&:hover': { backgroundColor: '#ffa726' },
            }}
            onClick={() => setShowGuide(true)}
          >
            Install
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: '#fff',
              borderColor: '#ff9800',
              '&:hover': { backgroundColor: '#333', borderColor: '#ffa726' },
            }}
            onClick={() => setSkipInstall(true)}
          >
            Continue
          </Button>
        </Box>
        

        {/* <Fab
  color="primary"
  aria-label="install"
  onClick={handleInstall}
  sx={{
    backgroundColor: '#020420',
    color: '#fff',
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 1000,
    '&:hover': { backgroundColor: '#1c1c4d' },
  }}
>
  <DownloadIcon />
</Fab> */}

<Dialog open={showGuide} onClose={() => setShowGuide(false)}>
  <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
    Installation Guide
  </DialogTitle>
  <DialogContent>
  <Stepper
  alternativeLabel
  connector={<CustomConnector isIOS={isIOS} />}
>
  {isIOS ? (
    <>
      <Step>
        <StepLabel
          StepIconComponent={() => <CustomStepIcon icon={<IosShareIcon sx={iconStyles} />} />}
        >
          <Typography sx={labelStyles}>
            Tap the <strong>Share</strong> icon.
          </Typography>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel
          StepIconComponent={() => <CustomStepIcon icon={<HomeScreenIcon sx={iconStyles} />} />}
        >
          <Typography sx={labelStyles}>
            Select <strong>Add to Home Screen</strong>.
          </Typography>
        </StepLabel>
      </Step>
    </>
  ) : (
    <>
      <Step>
        <StepLabel
          StepIconComponent={() => <CustomStepIcon icon={<DownloadIcon sx={iconStyles} />} />}
        >
          <Typography sx={labelStyles}>
            Tap the <strong>Install</strong> button below to install the app.
          </Typography>
        </StepLabel>
      </Step>

    </>
  )}
</Stepper>

    {!isIOS && (
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#ff9800',
          color: '#fff',
          mt: 2,
          '&:hover': { backgroundColor: '#ffa726' },
        }}
        onClick={handleInstall}
      >
        Install Now
      </Button>
    )}
  </DialogContent>
</Dialog>

      </Box>
    );
  }

  return (
    <div className="app-container">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/home" element={<ProtectedRoute element={<HomePage />} />} />
            <Route path="/profile" element={<ProtectedRoute element={<ProfilePage />} />} />
            <Route path="/events" element={<ProtectedRoute element={<EventsPage />} />} />
            <Route path="/sign-in" element={<SignInPage />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<ProtectedRoute element={<HomePage />} />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/event/:id" element={<EventDetailsPage />} />
            <Route path="/check-in" element={<ProtectedRoute element={<CheckInPage />} />} />
            <Route path="/support" element={<ProtectedRoute element={<SupportPage />} />} />
            <Route path="/live" element={<ProtectedRoute element={<LivePage />} />} />
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
};

export default App;
