import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Tooltip, Legend, BarElement } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import BottomNavBar from './BottomNavBar';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_EVENTS_QUERY, ORGANIZER_PROFILE_INFO_QUERY, GET_EVENT_QUERY } from '../graphql/queries';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Tooltip, Legend);

const HomePage = () => {
  const navigate = useNavigate();
  const [selectedEventId, setSelectedEventId] = useState(null); // Track selected event

  // Queries to get data
  const { data: userData, loading: userLoading } = useQuery(ORGANIZER_PROFILE_INFO_QUERY);
  const { data: eventsData, loading: eventsLoading } = useQuery(GET_EVENTS_QUERY);
  const { data: eventDetailData, loading: eventDetailLoading } = useQuery(GET_EVENT_QUERY, {
    variables: { getEventInput2: { eventId: selectedEventId } },
    skip: !selectedEventId, // Skip query if no event is selected
  });

  // Set the first event as default selected
  useEffect(() => {
    if (!selectedEventId && eventsData?.getEvents?.length) {
      setSelectedEventId(eventsData.getEvents[0].id);
    }
  }, [eventsData, selectedEventId]);

  // Get current month and year for comparison
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const previousMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

  const handleCreateEvent = () => navigate('/events', { state: { openModal: true } });
  const handleCheckIn = () => navigate('/check-in');

  // Count event types for Doughnut chart
  const eventTypeCounts = eventsData?.getEvents?.reduce((acc, event) => {
    acc[event.type] = (acc[event.type] || 0) + 1;
    return acc;
  }, {});

  const doughnutData = {
    labels: Object.keys(eventTypeCounts || {}),
    datasets: [
      {
        data: Object.values(eventTypeCounts || {}),
        backgroundColor: ['#020420', '#4caf50', '#ff9800', '#f44336'],
        hoverBackgroundColor: ['#020420', '#4caf50', '#ff9800', '#f44336'],
      },
    ],
  };

  // Get user name
  const userName = userData?.me?.preferredName || userData?.me?.firstName || JSON.parse(localStorage.getItem('authenticatedUser')).firstName;

  // Calculate monthly change
  const eventsThisMonth = eventsData?.getEvents?.filter(event => {
    const eventDate = new Date(event.startDate);
    return eventDate.getMonth() === currentMonth && eventDate.getFullYear() === currentYear;
  }).length || 0;

  const eventsLastMonth = eventsData?.getEvents?.filter(event => {
    const eventDate = new Date(event.startDate);
    return eventDate.getMonth() === previousMonth && eventDate.getFullYear() === previousMonthYear;
  }).length || 0;

  const monthChange = eventsThisMonth - eventsLastMonth;

  const allStatuses = ['PENDING', 'APPROVED', 'REJECTED', 'SUBMITTED'];
  const statusCounts = allStatuses.reduce((acc, status) => {
    acc[status] = eventDetailData?.getEvent?.attendees?.filter((attendee) => attendee.status === status).length || 0;
    return acc;
  }, {});
  
  const barData = {
    labels: Object.keys(statusCounts || {}),
    datasets: [
      {
        label: 'Attendees Status',
        data: Object.values(statusCounts || {}),
        backgroundColor: ['#4caf50', '#ff9800', '#f44336', '#3f51b5'],
        hoverBackgroundColor: ['#4caf50', '#ff9800', '#f44336', '#3f51b5'],
      },
    ],
  };

  if (userLoading || eventsLoading) return <p>Loading...</p>;

  return (
    <div className="home-page">
      <div className="header">
        <h2>Welcome Back 👋</h2>
        <p>{userName}</p>
      </div>

      {/* Balance Section with Create and Manage buttons */}
      <div className="balance-section">
        <div className="balance-info">
          <h3>Total Events</h3>
          <p>{eventsData?.getEvents?.length || 0}</p>
          <span className={`change ${monthChange >= 0 ? 'positive' : 'negative'}`}>
            {monthChange >= 0 ? `+${monthChange}` : monthChange} this month
          </span>
        </div>
        <div className="balance-actions">
          <button className="primary-button create-event" onClick={handleCreateEvent}>
            Create Event
          </button>
          <button className="primary-button manage-events" onClick={() => navigate('/events')}>
            Manage Events
          </button>
        </div>

        {/* Check-In Button */}
        <div className="important-action">
          <button className="important-button" onClick={handleCheckIn}>
            Check-In for Event
          </button>
        </div>
      </div>
      
      {/* Doughnut Chart for Event Type Breakdown */}
      <div className="stats-graphs-section">
        <div className="stat-card">
          <h4>Event Type Breakdown</h4>
          <Doughnut data={doughnutData} />
        </div>
      </div>



      <div className="stats-graphs-section">
  <div className="stat-card" style={{ padding: '16px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
    <Typography variant="h6" sx={{ textAlign: 'center', mb: 2 }}>
      Attendee Status for {eventDetailData?.getEvent?.name || 'Selected Event'}
    </Typography>

    <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
      <InputLabel>Select Event</InputLabel>
      <Select
        value={selectedEventId || ''}
        label="Select Event"
        onChange={(e) => setSelectedEventId(e.target.value)}
      >
        {eventsData?.getEvents?.map((event) => (
          <MenuItem key={event.id} value={event.id}>
            {event.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    {eventDetailLoading ? (
      <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', mt: 2 }}>
        Loading attendee status...
      </Typography>
    ) : (
      <Box sx={{ mt: 2, p: 2 }}>
        <Bar data={barData} options={{
          responsive: true,
          plugins: {
            legend: { display: false },
            tooltip: { callbacks: { label: (context) => `${context.label}: ${context.raw || 0}` } },
          },
          scales: {
            y: { beginAtZero: true, ticks: { precision: 0 } },
          },
        }} />
      </Box>
    )}
  </div>
</div>



      <div style={{ paddingBottom: '80px' }} />

      {/* Bottom navigation bar */}
      <BottomNavBar />
    </div>
  );
};

export default HomePage;
