import React,{useEffect} from "react";
import { ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Loader } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
// import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { FaceLivenessDetectorCore } from '@aws-amplify/ui-react-liveness';
import { VALIDATE_ENTRY_TO_EVENT,CREATE_EVENT_ENTRY_STREAM } from "../graphql/mutations";
import { useNavigate } from "react-router-dom";

// import FaceLiveness from './FaceLiveness';
// import ReferenceImage from './ReferenceImage';
import {
  View,
  Flex,
} from '@aws-amplify/ui-react';
import { useLocation } from "react-router-dom";

import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { useMutation } from "@apollo/client";
Amplify.configure(awsconfig);

const LivePage = () => {
  const [loading, setLoading] = React.useState(true);
    const [sessionId, setSessionId] = React.useState(null)
    // const [faceLivenessAnalysis, setFaceLivenessAnalysis] = useState(null);
    const location = useLocation();
    const { streamId, eventId } = location.state || {};
    const navigate = useNavigate();
    const [createEventEntryStream] = useMutation(CREATE_EVENT_ENTRY_STREAM);


    // const {data, loading: loadingData, error} = useMutation(VALIDATE_ENTRY_TO_EVENT);

    useEffect(() => {
      // const fetchOrReadLivenessSession = async () => {
      // const storedSessionId = localStorage.getItem('streamId');
          
      //     if (storedSessionId) {
      //       console.log('Using sessionId from localStorage:', storedSessionId);
      //       setSessionId(storedSessionId);
      //       setLoading(false);
      //     } else {
      //       alert('No sessionId found in localStorage');
      //       }
      //   };
      //   fetchOrReadLivenessSession();
      if (!streamId || !eventId) {
        alert("No state found! Redirecting...");
        navigate('/check-in');
      } else {
        setSessionId(streamId);
        setLoading(false);
        // console.log("StreamId:", streamId);
        // console.log("EventId:", eventId);
      }
    }, [streamId, eventId, navigate]);


      const [validateEntryToEvent] = useMutation(VALIDATE_ENTRY_TO_EVENT);


      const handleAnalysisComplete = async () => {
        try {
          setLoading(true);
  
          const {data} = await validateEntryToEvent({
            variables: {
              input: {
                sessionId,
              },
            },
          });

          const response = data.validateEntryToEvent;
          
          if(response.status === "SUCCEEDED") {
              alert(
                response.user.preferredName || response.user.firstName + " has been checked in."
              );

            const payload = {
              eventId: eventId, // Dynamically set the selected event ID
            };
      
            // Call the CreateEventEntryStream mutation
            const { data: createEventData } = await createEventEntryStream({
              variables: { input: payload },
            });
      
            
      if (createEventData?.createEventEntryStream) {
        const newStreamId = createEventData.createEventEntryStream;

        // Ask the user if they want to check in another person
        const checkAnother = window.confirm(
          "Do you want to check in another person?"
        );

        if (checkAnother) {
          // Update sessionId with the new streamId
          setSessionId(newStreamId);
        } else {
          // Navigate to a different page or show a message to stop
          alert("Check-in process stopped.");
          navigate('/check-in');
        }
      }
    }
  } catch (error) {
    alert("Failed to validate entry. Please try again.");
    navigate(-1);
  } finally {
    setLoading(false);
  }
};




  // const getfaceLivenessAnalysis = (faceLivenessAnalysis) => {
  //   if (faceLivenessAnalysis !== null) {
  //     setFaceLivenessAnalysis(faceLivenessAnalysis)
  //   }
  // }

  // const tryagain = () =>{
  //   setFaceLivenessAnalysis(null)
  // }

  const credentialProvider = async () => {
    try {
      // Fetch the credentials from your custom source
      console.log("Fetching credentials...");
      const credentials = await fetchMyCustomCredentials();
  
      // Validate the fetched credentials
      if (
        !credentials ||
        !credentials.accessKeyId ||
        !credentials.secretAccessKey
      ) {
        // throw new CredentialsProviderError("Invalid credentials");
        console.error("Invalid credentials");
      }
  
      // Return the credentials in the required format
      return {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken, // Optional
      };
    } catch (error) {
      console.error("Error fetching credentials:", error);
    }
  };

  const fetchMyCustomCredentials = async () => {
    // Replace this with logic to fetch credentials from your custom source
    return {
      accessKeyId: "AKIAW5WU4YWX7DRJ2SE7",
      secretAccessKey: "637Rhoexg+OXlqtLDuSdmy92P3cSqUVdEueb4H/A",
    };
  };

  const handleError = (error) => {
    const errorMessage = String(error.message || error.error || "");
    if (errorMessage.includes("Liveness session has results available")) {
      alert("Liveness session has results available. Please try again.");
      navigate(-1);
    }
    else if (errorMessage.includes("Liveness session has expired")) {
      alert("Liveness session has expired. Please try again.");
      navigate(-1);
    }
    console.error("Error:", error);
  }
  


  return (
    <ThemeProvider>
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
      >
        <View
          as="div"
          maxHeight="600px"
          height="600px"
          width="740px"
          maxWidth="740px"
        >
          {loading ? (
                <Loader />
            ) : (
                <FaceLivenessDetectorCore
                    sessionId={sessionId}
                    region="us-east-1"
                    onAnalysisComplete={handleAnalysisComplete}
                    onError={handleError}
                    config={{ credentialProvider }}
                />
            )}
        </View>
      </Flex>
    </ThemeProvider>


  );
}

export default LivePage;