
import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = ({ success = false }) => {
  return (
    <div className="loading-spinner">
      <div className={`spinner ${success ? 'success' : ''}`}></div>
    </div>
  );
};

export default LoadingSpinner;
