import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Autocomplete,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import './EditEventModal.css';
import { UPDATE_EVENT_MEDIA_MUTATION, ADD_MEDIA_TO_EVENT_MUTATION } from '../graphql/mutations';






const theme = createTheme({
  palette: {
    primary: {
      main: '#020420', // Primary color
    },
  },
});

const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent($input: iUpdateEvent!) {
    updateEvent(input: $input) {
      id
      name
      description
      venue
      type
      startDate
      endDate
    }
  }
`;


const eventTypeOptions = ['Conference', 'Workshop', 'Meetup', 'Webinar'];

const EditEventModal = ({ event, eventImage, lastMediaId, onClose, onUpdate }) => {

  const [updateEventMedia] = useMutation(UPDATE_EVENT_MEDIA_MUTATION, {
    onCompleted: () => {
      console.log('Event media updated successfully.');
    },
    onError: (error) => {
      console.error('Error updating event media:', error);
    },
  });

  const [eventData, setEventData] = useState({
    name: event.name,
    description: event.description,
    venue: event.venue,
    type: event.type,
    startDate: event.startDate,
    endDate: event.endDate,
  });



  const [photo, setPhoto] = useState(null);

  const [updateEvent] = useMutation(UPDATE_EVENT_MUTATION, {
    onCompleted: (data) => {
      onUpdate(data.updateEvent);
      onClose();
    },
    onError: (error) => {
      console.error('Error updating event:', error);
    },
  });

  const [addMediaToEvent] = useMutation(ADD_MEDIA_TO_EVENT_MUTATION, {
    onCompleted: () => {
      console.log('Media added successfully.');
    },
    onError: (error) => {
      console.error('Error adding media:', error);
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData({ ...eventData, [name]: value });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    console.log('Selected File:', file);
    setPhoto(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedData = {
      ...eventData,
      startDate: new Date(eventData.startDate).toISOString(),
      endDate: new Date(eventData.endDate).toISOString(),
      eventId: event.id,
    };
  
    try {
      // Update the event details
      await updateEvent({ variables: { input: formattedData } });
      // console.log('Event updated successfully.');
  
      // If a new photo is selected, update or add the event media
      if (photo) {
        // console.log('Uploading event media:', photo);
  
        if (lastMediaId*0) {
          // If mediaId exists, update the existing media
          await updateEventMedia({
            variables: {
              input: {
                eventId: event.id,
                mediaId: lastMediaId,
                media: photo,
              },
            },
          });
          // console.log('Event media updated successfully.');
        } else {
          // If no mediaId, add new media
          await addMediaToEvent({
            variables: {
              input: {
                eventId: event.id,
                media: photo,
              },
            },
          });
          // console.log('Event media added successfully.');
        }
      }
  
      onUpdate({ ...event, ...formattedData });
      onClose();
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };
  

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Edit Event</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
              {/* <Button variant="contained" component="label">
                Upload Photo
                <input type="file" accept="image/*" hidden onChange={handlePhotoChange} />
              </Button> */}
              <Box position="relative" width="100%" height="200px" mb={2}>
  <img
    src={photo ? URL.createObjectURL(photo) : eventImage}
    alt="Event"
    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
  />
  {/* Overlay edit button */}
  <Button
    variant="contained"
    component="label"
    sx={{
      position: 'absolute',
      top: '10px',
      right: '10px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: '#fff',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
      },
    }}
  >
    Edit Photo
    <input
      type="file"
      accept="image/*"
      hidden
      onChange={handlePhotoChange}
    />
  </Button>
</Box>

              <TextField
                label="Event Name"
                name="name"
                value={eventData.name}
                onChange={handleChange}
                required
                fullWidth
              />
              <Autocomplete
                options={eventTypeOptions}
                value={eventData.type}
                onChange={(e, newValue) => setEventData({ ...eventData, type: newValue })}
                renderInput={(params) => (
                  <TextField {...params} label="Event Type" fullWidth required />
                )}
              />
              <TextField
                label="Venue"
                name="venue"
                value={eventData.venue}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="Description"
                name="description"
                value={eventData.description}
                onChange={handleChange}
                multiline
                rows={3}
                fullWidth
              />
              <TextField
                label="Start Date"
                name="startDate"
                type="datetime-local"
                value={new Date(eventData.startDate).toISOString().slice(0, 16)}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="End Date"
                name="endDate"
                type="datetime-local"
                value={new Date(eventData.endDate).toISOString().slice(0, 16)}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">Cancel</Button>
            <Button type="submit" color="primary" variant="contained">Save Changes</Button>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default EditEventModal;
