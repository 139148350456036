import React, { useState, useEffect} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
} from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';

const MappingModal = ({
  isOpen,
  onClose,
  fileHeaders,
  columnMapping,
  setColumnMapping,
  requiredFields,
  handleSaveMapping,
}) => {
  const [activeStep, setActiveStep] = useState(0);


  useEffect(() => {
    if (isOpen) {
        setActiveStep(0);
      const modalElement = document.getElementById('mapping-modal-title');
      modalElement?.focus();
    }
  }, [isOpen]);

  const handleNext = () => {
    if (activeStep < requiredFields.length - 1) {
      setActiveStep((prev) => prev + 1);
    } else {
      handleSaveMapping(); // Save mapping when the last step is reached
      onClose(); // Close modal after saving
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleFieldMappingChange = (value) => {
    const updatedMapping = columnMapping.map((m) =>
      m.appField === requiredFields[activeStep]
        ? { ...m, sheetColumn: value }
        : m
    );
    setColumnMapping(updatedMapping);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="mapping-modal-title"
      aria-describedby="mapping-modal-description"
    >
     <DialogTitle
  id="mapping-modal-title"
  tabIndex={-1}
  sx={{
    bgcolor: '#020420',
    color: '#fff',
    textAlign: 'center',
  }}
>
  Map Columns
</DialogTitle>

      <div style={{ height: '12px' }}></div>
      <DialogContent>
        <Box
          sx={{
            mb: 3,
            overflowX: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              flexWrap: { xs: 'nowrap', md: 'wrap' },
              justifyContent: { xs: 'flex-start', md: 'center' },
              overflowX: { xs: 'auto', md: 'visible' },
            }}
          >
            {requiredFields.map((field, index) => (
              <Step key={index}>
                <StepLabel>{field}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Typography
          id="mapping-modal-description"
          variant="h6"
          gutterBottom
          sx={{ textAlign: 'center', color: '#020420', fontSize: { xs: '1rem', md: '1.2rem' } }}
        >
          Map the field: <strong>{requiredFields[activeStep]}</strong>
        </Typography>
        <Autocomplete
          options={fileHeaders}
          value={
            columnMapping.find((m) => m.appField === requiredFields[activeStep])
              ?.sheetColumn || ''
          }
          onChange={(_, newValue) => handleFieldMappingChange(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Column"
              variant="outlined"
              fullWidth
              InputLabelProps={{ style: { color: '#020420' } }}
            />
          )}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#020420',
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: { xs: 'center', sm: 'space-between' },
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 1,
          p: 2,
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            color: '#020420',
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          Cancel
        </Button>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {activeStep > 0 && (
            <Button
              onClick={handleBack}
              variant="outlined"
              sx={{
                borderColor: '#020420',
                color: '#020420',
                fontSize: '0.9rem',
                width: { xs: '100%', sm: 'auto' },
              }}
            >
              Back
            </Button>
          )}
          <Button
            onClick={handleNext}
            variant="contained"
            sx={{
              bgcolor: '#020420',
              color: '#fff',
              '&:hover': { bgcolor: '#010316' },
              fontSize: '0.9rem',
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            {activeStep === requiredFields.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default MappingModal;
