// src/graphql/queries.js
import { gql } from '@apollo/client';

export const GET_EVENTS_QUERY = gql`
  query GetEvents {
    getEvents {
      id
      name
      type
      venue
      startDate
      endDate
      description
      eventMedia {
      mediaUrl
    }
    }
  }
`;

export const ORGANIZER_PROFILE_INFO_QUERY = gql`
  query OrganizerProfileInfo {
    organizerProfileInfo {
    email
    firstName
    lastName
    preferredName
    profilePicUrl
    phoneNumber
    }
  }
`;


export const GET_EVENT_QUERY = gql`
  query GetEvent($getEventInput2: iGetEvent!) {
    getEvent(input: $getEventInput2) {
      id
      name
      description
      venue
      type
      startDate
      endDate
      eventMedia {
      mediaUrl
      id
      }
      attendees {
        user {
          firstName
          lastName
          id
        }
        status
        verificationLink
      }
    }
  }
`;


export const ORGANIZER_TICKETS_QUERY = gql`
  query OrganizerTickets {
    organizerTickets {
      id
      title
      category
      createdAt
      comments {
        text
        createdAt
        isResponse
      }
    }
  }
`;

export const ORGANIZER_TICKET_QUERY = gql`
  query OrganizerTicket($input: iOrganizerTicket!) {
  organizerTicket(input: $input) {
    id
    isResponse
    text
    createdAt
  }
  }
`;
