// src/graphql/mutations.js
import { gql } from '@apollo/client';

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent($input: iCreateEvent!) {
    createEvent(input: $input) {
      id
      name
      type
      venue
      startDate
      endDate
      description
      qrCodeEntry
    }
  }
`;

export const DELETE_EVENT_MUTATION = gql`
  mutation DeleteEvent($input: iDeleteEvent!) {
    deleteEvent(input: $input)
  }
`;

export const ADD_USER_TO_EVENT_MUTATION = gql`
  mutation AddUserToEvent($input: iAddUserToEvent!) {
    addUserToEvent(input: $input)
  }
`;

export const UPDATE_ORGANIZER_MUTATION = gql`
  mutation UpdateOrganizer($input: iUpdateOrganizer!) {
    updateOrganizer(input: $input)
  }
`;

export const UPDATE_ORGANIZER_PASSWORD_MUTATION = gql`
  mutation UpdateOrganizerPassword($input: iUpdateOrganizerPassword!) {
    updateOrganizerPassword(input: $input)
  }
`;

export const UPDATE_ORGANIZER_PHOTO_MUTATION = gql`
mutation UpdateOrganizerPhoto($input: iUploadPhoto!) {
  updateOrganizerPhoto(input: $input)
}
`;

export const ADD_MEDIA_TO_EVENT_MUTATION = gql`
  mutation AddMediaToEvent($input: iAddMediaToEvent!) {
    addMediaToEvent(input: $input) 
  }
`;


export const SCAN_QR_CODE = gql`
  mutation ScanQRCode($input: iScanQRCode!) {
    scanQRCode(input: $input) {
      firstName
      preferredName
    }
  }
`;


export const CREATE_EVENT_ENTRY_STREAM = gql`
  mutation CreateEventEntryStream($input: iCreateEventEntryStream!) {
  createEventEntryStream(input: $input)
}
`;


export const ADD_USER_SHEET_TO_EVENT = gql`
  mutation AddUserSheetToEvent($input: iAddUserSheetToEvent!) {
    addUserSheetToEvent(input: $input) {
      results {
        error
        rowNumber
        success
      }
    }
  }
`;


export const RESEND_VERIFICATION_NOTICE_MUTATION = gql`
  mutation ResendVerificationNotice($input: iResendVerificationNotice!) {
    resendVerificationNotice(input: $input)
  }
`;

export const ADD_SUPPORT_TICKET_MUTATION = gql`
  mutation AddSupportTicket($input: iAddSupportTicket!) {
    addSupportTicket(input: $input)
  }
`;


export const ADD_SUPPORT_TICKET_COMMENT_MUTATION = gql`
  mutation AddSupportTicketComment($input: iAddSupportTicketComment!) {
    addSupportTicketComment(input: $input)
  }
`;

export const UPDATE_EVENT_MEDIA_MUTATION = gql`
mutation UpdateEventMedia($input: iUpdateEventMedia!) {
  updateEventMedia(input: $input)
}
`;

export const FORGOT_PASSWORD_ORGANIZER_MUTATION = gql`
  mutation ForgotPasswordOrganizer($forgotPasswordOrganizerInput2: iForgotPassword!) {
    forgotPasswordOrganizer(input: $forgotPasswordOrganizerInput2)
  }
`;

export const VERIFY_FORGOT_PASSWORD_ORGANIZER_MUTATION = gql`
  mutation VerifyForgotPasswordOrganizer($input: iVerifyForgotPassword!) {
    verifyForgotPasswordOrganizer(input: $input)
  }
`;



export const VALIDATE_ENTRY_TO_EVENT = gql`
mutation ValidateEntryToEvent($input: iValidateEntryToEvent!) {
  validateEntryToEvent(input: $input) {
    user {
      firstName
      preferredName
    }
    status
  }
}
`;