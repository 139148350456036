import React, { useState,useEffect } from 'react';
import { useMutation,useQuery } from '@apollo/client';
import BottomNavBar from './BottomNavBar';
import './ProfilePage.css';
import { useAuth } from '../context/AuthContext';
import profile from '../assets/profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { UPDATE_ORGANIZER_MUTATION, UPDATE_ORGANIZER_PASSWORD_MUTATION, UPDATE_ORGANIZER_PHOTO_MUTATION } from '../graphql/mutations';
import { ORGANIZER_PROFILE_INFO_QUERY } from '../graphql/queries';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LoadingSpinner from './LoadingSpinner';



const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%', // Responsive width for mobile
  maxWidth: 500, // Maximum width for larger screens
  bgcolor: 'background.paper',
  borderRadius: '12px',
  boxShadow: 24,
  p: 4,
};


const cancelButtonStyle = {
  color: '#020420',
  borderColor: '#020420',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#f2f2f2',
  },
};

const buttonStyle = {
  backgroundColor: '#020420',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#1c1c4d',
  },
};


const ProfilePage = () => {
  const { logout } = useAuth();
  // const storedUser = JSON.parse(localStorage.getItem('authenticatedUser')) || {};
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const navigate = useNavigate();


  const { data, loading, error } = useQuery(ORGANIZER_PROFILE_INFO_QUERY);

  useEffect(() => {
    if (data && data.organizerProfileInfo) {
      const { firstName, lastName, preferredName, email, phoneNumber } = data.organizerProfileInfo;
      setUserData({
        firstName: firstName || '',
        lastName: lastName || '',
        preferredName: preferredName || '',
        email: email || '',
        phoneNumber: phoneNumber || '',
      });
    }
  }, [data]);
  // const [photo, setPhoto] = useState(null);
  
  
  
  const { firstName, lastName, preferredName, email, profilePicUrl } = data?.organizerProfileInfo || {};
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    preferredName: '',
    email: '',
    phoneNumber: '',
  });


  
  
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    repeatPassword: ''
  });

  const [updateOrganizer] = useMutation(UPDATE_ORGANIZER_MUTATION, {
    onCompleted: () => {
      alert('Profile updated successfully');
      setIsEditingProfile(false);
    },
    onError: (error) => {

      const errorMessage = error?.graphQLErrors?.[0]?.extensions?.wrongConditions;
      // console.error("Error updating profile:", errorMessage);
      alert(errorMessage || 'Failed to update profile');
    },
    refetchQueries: [{ query: ORGANIZER_PROFILE_INFO_QUERY }] // Refetch the profile info
  });
  
  const [updateOrganizerPassword] = useMutation(UPDATE_ORGANIZER_PASSWORD_MUTATION, {
    onCompleted: () => {
      alert('Password updated successfully');
      setPasswordData({ oldPassword: '', newPassword: '', repeatPassword: '' });
      setIsChangingPassword(false);
    },
    onError: (error) => {
      console.error("Error updating password:", error);
      alert('Failed to update password');
    }
  });

  const [updateOrganizerPhoto] = useMutation(UPDATE_ORGANIZER_PHOTO_MUTATION, {
    onCompleted: () => {
      alert('Profile photo updated successfully');
    },
    onError: (error) => {
      console.error('Error updating profile photo:', error);
      alert('Failed to update profile photo');
    },
    refetchQueries: [{ query: ORGANIZER_PROFILE_INFO_QUERY }],
  });


  const handlePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoadingPhoto(true);
      try {
        await updateOrganizerPhoto({
          variables: {
            input: {
              photo: file,
            },
          },
        });
      } catch (error) {
        console.error('Error uploading photo:', error);
      } finally {
        setLoadingPhoto(false);
      }
    }
  };

  const handleEditProfileOpen = () => {
    if (data && data.organizerProfileInfo) {
      const { firstName, lastName, preferredName, email, phoneNumber } = data.organizerProfileInfo;
      setUserData({
        firstName: firstName || '',
        lastName: lastName || '',
        preferredName: preferredName || '',
        email: email || '',
        phoneNumber: phoneNumber || '',
      });
    }
    setIsEditingProfile(true);
  };
  

  const handleLogout = async () => {
    await logout();
  };

  const handleUserDataChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({ ...prevUserData, [name]: value }));
  };


  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 20;
    if (/[A-Z]/.test(password)) strength += 20;
    if (/[a-z]/.test(password)) strength += 20;
    if (/\d/.test(password)) strength += 20;
    if (/[^A-Za-z0-9]/.test(password)) strength += 20;
    return strength;
  };

  const handlePasswordDataChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });

    if (name === 'newPassword') {
      setPasswordStrength(calculatePasswordStrength(value));
    }
  };



  const handlePasswordUpdate = (e) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.repeatPassword) {
      setPasswordError('Passwords do not match');
      return;
    }
    if (passwordStrength < 60) {
      setPasswordError('Password is too weak');
      return;
    }

    setPasswordError('');
    updateOrganizerPassword({ variables: { input: { oldPassword: passwordData.oldPassword, newPassword: passwordData.newPassword } } });
  };




  const handleUserUpdate = (e) => {
    e.preventDefault();
    updateOrganizer({ variables: { input: userData } });
  };
  
  if (loading || loadingPhoto) {
    return <LoadingSpinner />;
  }
  
    
  if (error) return <p>Error loading profile</p>;

  return (
    <div className="profile-page">
      <div className="profile-header">
  <div className="profile-avatar">
    <img src={profilePicUrl || profile} alt="Profile" />
  </div>
  <h2>{preferredName || firstName} {lastName}</h2>
  <p>{email}</p>
  <button className="edit-button" onClick={handleEditProfileOpen}>
  Edit Profile
</button>
  <button className="edit-button" onClick={() => setIsChangingPassword(true)}>
    Change Password
  </button>
</div>

      {/* Edit Profile Modal */}
      {isEditingProfile && (
        <Modal open={isEditingProfile} onClose={() => setIsEditingProfile(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            Edit Profile
          </Typography>
          <form onSubmit={handleUserUpdate}>
            <Grid container spacing={2}>
              {/* Profile Photo with Edit Icon */}
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ position: 'relative' }}>
                  <img
                    src={profilePicUrl || profile}
                    alt="Profile"
                    style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="icon-button-file"
                    onChange={handlePhotoChange}
                  />
                  <label htmlFor="icon-button-file" style={{ position: 'absolute', bottom: 0, right: 0 }}>
                    <IconButton component="span">
                      <EditIcon />
                    </IconButton>
                  </label>
                </div>
              </Grid>
      
              {/* First Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="firstName"
                  fullWidth
                  value={userData.firstName}
                  onChange={handleUserDataChange}
                  required
                />
              </Grid>
      
              {/* Last Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  fullWidth
                  value={userData.lastName}
                  onChange={handleUserDataChange}
                  required
                />
              </Grid>
      
              {/* Preferred Name */}
              <Grid item xs={12}>
                <TextField
                  label="Preferred Name"
                  name="preferredName"
                  fullWidth
                  value={userData.preferredName}
                  onChange={handleUserDataChange}
                />
              </Grid>
      
              {/* Email */}
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  fullWidth
                  type="email"
                  value={userData.email}
                  onChange={handleUserDataChange}
                  required
                />
              </Grid>
      
              {/* Phone Number */}
              <Grid item xs={12}>
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  fullWidth
                  type="tel"
                  value={userData.phoneNumber}
                  onChange={handleUserDataChange}
                />
              </Grid>
      
              {/* Action Buttons */}
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button type="submit" variant="contained" sx={{ ...buttonStyle, width: '48%' }}>
                    Save Changes
                  </Button>
                  <Button
                    onClick={() => setIsEditingProfile(false)}
                    variant="outlined"
                    sx={{ ...cancelButtonStyle, width: '48%' }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
)}

      {/* Change Password Modal */}
      {isChangingPassword && (
        <div className="edit-modal-overlay">
          <div className="edit-modal-content">
            <h2>Change Password</h2>
            <form onSubmit={handlePasswordUpdate}>
              <input
                type="password"
                name="oldPassword"
                placeholder="Old Password"
                value={passwordData.oldPassword}
                onChange={handlePasswordDataChange}
                required
              />
              <input
                type="password"
                name="newPassword"
                placeholder="New Password"
                value={passwordData.newPassword}
                onChange={handlePasswordDataChange}
                required
              />
              <div className="password-strength-bar">
                <div
                  className={`strength-meter strength-${passwordStrength}`}
                  style={{ width: `${passwordStrength}%` }}
                ></div>
              </div>
              <input
                type="password"
                name="repeatPassword"
                placeholder="Repeat New Password"
                value={passwordData.repeatPassword}
                onChange={handlePasswordDataChange}
                required
              />
              {passwordError && <p className="password-error">{passwordError}</p>}
              <div className="edit-modal-actions">
                <button type="submit" className="save-button">Update Password</button>
                <button type="button" className="cancel-button" onClick={() => setIsChangingPassword(false)}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="profile-options">
      <div className="option-item" onClick={() => navigate('/support')}>
          <p>Support</p>
          <span>→</span>
        </div>
        <button className="logout-button" onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '8px' }} />
          Log Out
        </button>
      </div>

      <BottomNavBar />
    </div>
  );
};


export default ProfilePage;
