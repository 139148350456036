// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/global.css'; // Import global CSS
import ApolloProviderSetup from './api/ApolloProviderSetup'; // Import your Apollo setup

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then((registration) => console.log('Service Worker registered', registration))
    .catch((error) => console.error('Service Worker registration failed', error));
}

<meta name="viewport" content="width=device-width, initial-scale=1.0" />


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProviderSetup>  {/* Wrap App with ApolloProviderSetup */}
      <App />
    </ApolloProviderSetup>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


