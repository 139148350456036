// ForgotPasswordPage.jsx
import React, { useState } from 'react';
import Layout from './Layout';
import './SignInPage.css'; // Reuse the same CSS as SignInPage for consistency
import { useMutation } from '@apollo/client';
import validator from 'validator';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import CodeInput from 'react-code-input';

import {
  VERIFY_FORGOT_PASSWORD_ORGANIZER_MUTATION,
  FORGOT_PASSWORD_ORGANIZER_MUTATION,
} from '../graphql/mutations';

const ForgotPasswordPage = () => {
  const [step, setStep] = useState(1); // Steps: 1 - Request Reset, 2 - Verify Token, 3 - Set Password, 4 - Success
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [inputType, setInputType] = useState(null); // 'email' or 'phone'
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Initialize mutations
  const [forgotPasswordOrganizer] = useMutation(
    FORGOT_PASSWORD_ORGANIZER_MUTATION
  );
  
  const [verifyForgotPasswordOrganizer] = useMutation(
    VERIFY_FORGOT_PASSWORD_ORGANIZER_MUTATION,
    {
      onCompleted: (data) => {
        if (data.verifyForgotPasswordOrganizer) {
          if (step === 2) {
            // Token verification successful, move to set new password
            setStep(3);
          } else if (step === 3) {
            // Password reset successful, move to success message
            navigate.push('/signin');
          }
        } else {
          setError('Invalid token or password criteria not met.');
        }
        setLoading(false);
      },
      onError: (error) => {
        const wrongConditions =
          error?.graphQLErrors?.[0]?.extensions?.wrongConditions || [];
        const message = wrongConditions.length
          ? wrongConditions.join(', ')
          : 'An error occurred. Please try again later.';
        setError(message);
        setLoading(false);
      },
    }
  );

  // Function to calculate password strength
  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length > 5) strength += 20;
    if (password.length > 8) strength += 20;
    if (/[A-Z]/.test(password)) strength += 20;
    if (/[0-9]/.test(password)) strength += 20;
    if (/[^A-Za-z0-9]/.test(password)) strength += 20;
    return strength;
  };

  // Handler for requesting reset token
  const handleSubmitEmailOrPhone = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!emailOrPhone) {
      setError('Please enter your email or phone number.');
      setLoading(false);
      return;
    }

    let isEmail = false;
    // let isPhone = false;

    // Detect if input is a valid email
    if (validator.isEmail(emailOrPhone)) {
      isEmail = true;
      setInputType('email');
    }
    // Detect if input is a valid phone number
    else if (validator.isMobilePhone(emailOrPhone, 'any')) { // Specify locale if needed
      // isPhone = true;
      setInputType('phone');
    }
    // If neither, set an error
    else {
      setError('Please enter a valid email address or phone number.');
      setLoading(false);
      return;
    }

    try {
      const variables = {
        forgotPasswordOrganizerInput2: isEmail
          ? { email: emailOrPhone }
          : { phoneNumber: emailOrPhone },
      };

      await forgotPasswordOrganizer({ variables });

      setLoading(false);
      setStep(2); // Move to Verify Token step
    } catch (err) {
      // Extract and display error messages
      const wrongConditions =
        err?.graphQLErrors?.[0]?.extensions?.wrongConditions || [];
      const message = wrongConditions.length
        ? wrongConditions.join(', ')
        : 'An error occurred. Please try again later.';

      setError(message);
      setLoading(false);
    }
  };

  // Handler for verifying the token
  const handleVerifyToken = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!token) {
      setError('Please enter your token.');
      setLoading(false);
      return;
    }

    try {
      const variables = {
        input: {
          email: inputType === 'email' ? emailOrPhone : null,
          phoneNumber: inputType === 'phone' ? emailOrPhone : null,
          token,
          newPassword: null, // Since we're only verifying the token
        },
      };

      await verifyForgotPasswordOrganizer({ variables });

      // onCompleted will handle the step transition
    } catch (err) {
      // Error handling is managed in the mutation's onError
      setLoading(false);
    }
  };

  // Handler for resetting the password
  const handleSubmitNewPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    if (passwordStrength < 80) {
      setError('Password is not strong enough. Please use a stronger password.');
      setLoading(false);
      return;
    }

    if (!token) {
      setError('Please enter the token sent to you.');
      setLoading(false);
      return;
    }

    try {
      const variables = {
        input: {
          token,
          newPassword,
          email: inputType === 'email' ? emailOrPhone : null,
          phoneNumber: inputType === 'phone' ? emailOrPhone : null,
        },
      };

      await verifyForgotPasswordOrganizer({ variables });

      // onCompleted will handle the step transition
    } catch (err) {
      // Error handling is managed in the mutation's onError
      setLoading(false);
    }
  };

  // Handler for password input changes
  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    setPasswordStrength(calculatePasswordStrength(e.target.value));
  };


  const codeInputStyle = {
    fontFamily: 'monospace',
    border: '1px solid #ccc',
    borderRadius: '4px',
    margin: '0 4px',
    padding: '8px',
    width: '20px',
    textAlign: 'center',
  };


  return (
    <Layout>
      {step === 1 && (
        <div>
          <h1>Reset Password</h1>
          <form onSubmit={handleSubmitEmailOrPhone} className="verification-form">
            <TextField
              label="Email or Phone Number"
              type="text" // Use 'text' to accommodate both email and phone inputs
              placeholder="Enter your email or phone number"
              value={emailOrPhone}
              onChange={(e) => setEmailOrPhone(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            {inputType && (
              <p className="input-type-message">
                Detected as: {inputType === 'email' ? 'Email 📧' : 'Phone Number 📱'}
              </p>
            )}
            {error && <p className="error-message">{error}</p>}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading}
              className="submit-button"
              style={{
                marginTop: '16px',
                backgroundColor: '#020420',
                color: 'white',
              }}
            >
              {loading ? 'Sending Reset Token...' : 'Send Reset Token'}
            </Button>
          </form>
        </div>
      )}

      
      {step === 2 && (
        <div className="verify-token-container">
          <h1>Verify Token</h1>
          <p>Please enter the 6-digit code you received.</p>
          <form onSubmit={handleVerifyToken} className="verification-form">
            <CodeInput
              value={token}
              onChange={(value) => setToken(value)}
              fields={6}
              inputStyle={codeInputStyle}
              type="text"
              pattern="\d{1}"
              disabled={loading}
              autoFocus
            />
            {error && <p className="error-message">{error}</p>}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading}
              className="submit-button"
              style={{
                marginTop: '16px',
                backgroundColor: '#020420',
                color: 'white',
              }}
            >
              {loading ? 'Verifying Token...' : 'Verify Code'}
            </Button>
          </form>
        </div>
      )}

      {step === 3 && (
        <div>
          <h1>Set New Password</h1>
          <form onSubmit={handleSubmitNewPassword} className="verification-form">
            <TextField
              label="New Password"
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={handlePasswordChange}
              required
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="password-strength-bar" style={{ marginTop: '8px' }}>
              <div
                className="strength-meter"
                style={{
                  width: `${passwordStrength}%`,
                  height: '8px',
                  backgroundColor:
                    passwordStrength < 50
                      ? 'red'
                      : passwordStrength < 80
                      ? 'orange'
                      : 'green',
                }}
              ></div>
            </div>
            <TextField
              label="Confirm New Password"
              type={showPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            {error && <p className="error-message">{error}</p>}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading}
              style={{
                marginTop: '16px',
                backgroundColor: '#020420',
                color: 'white',
              }}
            >
              {loading ? 'Setting New Password...' : 'Set New Password'}
            </Button>
          </form>
        </div>
      )}

      {step === 4 && (
        <div className="success-message">
          <h1>Password Reset Successful!</h1>
          <p>You can now log in with your new password.</p>
        </div>
      )}
    </Layout>
  );
};

export default ForgotPasswordPage;
